import React, {useState} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Image from 'mui-image'
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {Dialog, DialogContent, Skeleton} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloudinaryImageView from "./CloudinaryImageView"

function ImageTimeline({images}) {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const getCols = () => {
        if (isLgBreakpoint) {
            return 3;
        } else if (isSmBreakpoint) {
            return 2;
        } else {
            return 1;
        }
    };
    const handleLoadingComplete = () => {
        setImagesLoaded(true);
    };


    const handleImageClick = (imageUrl) => {
        if (isLgBreakpoint === false || isSmBreakpoint === false) {
            return;
        }
        setSelectedImage(imageUrl);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const skeletons = 10;

    return (
        <>
            {!imagesLoaded && (
                <ImageList cols={getCols()}>
                    {[...Array(skeletons)].map((_, index) => (
                        <ImageListItem key={index}>
                            <Skeleton variant="rectangular" height={300}/>
                        </ImageListItem>
                    ))}
                </ImageList>

            )}


            <ImageList
                cols={getCols()}
                gap={8}
                variant="masonry"
            >
                {images.map((image, index) => (
                    <ImageListItem key={index} onLoad={handleLoadingComplete}>
                        <CloudinaryImageView image={image}/>
                    </ImageListItem>
                ))}
            </ImageList>

            <Dialog sx={{height: '100%'}} open={Boolean(selectedImage)} onClose={handleClose} fullWidth fullScreen>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{position: 'absolute', top: 8, right: 8}}
                >
                    <CloseIcon fontSize={'large'}/>
                </IconButton>
                <img
                    src={selectedImage}
                    alt="Full-size"
                    style={{maxHeight: '100%', objectFit: 'contain', margin: 'auto'}}
                />
            </Dialog>
        </>
    );
}

export default ImageTimeline;
