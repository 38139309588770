import { Upload, Button, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
const { Dragger } = Upload;
const serverUrl = window.location.href

function FileUpload({fetchPhotos, closeModal}) {

    const props = {
        name: 'photos',
        multiple: true,
        action: `${serverUrl}upload`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                fetchPhotos();
                closeModal();
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <PhotoCameraIcon fontSize='large'/>
            </p>
            <h3 className="ant-upload-text">We want to see your photos!</h3>
            <p className="ant-upload-text">Click here to upload your best shots.</p>
            <p className="ant-upload-hint">
                 Please think about what you are uploading as everyone will be able to see them.
            </p>
        </Dragger>
    );
}

export default FileUpload;
