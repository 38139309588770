import axios from 'axios';
import {useEffect, useState} from 'react';
import Photos from './Photos';
import FileUpload from "./Upload";
import Modal from "./Components/Modal";
import UploadModal from "./Components/UploadModal"
import "./App.css"

const serverUrl = window.location.href

function App() {
    const [photos, setPhotos] = useState([]);

    const fetchPhotos = async () => {
        // const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}/photos`);
        const result = await axios.get(`${serverUrl}photos`);
        setPhotos(result.data);
    };

    const updatePhotos = async () => {
        // const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}/photos-update`);
        const result = await axios.get(`${serverUrl}photos-update`);
        setPhotos(result.data);
        console.log('photos updated');
    };

    useEffect(() => {
        fetchPhotos()
    }, [])

    return (
        <div className={"wedding-app"}>
            <div className={'header'}>
                <h1>Hawthorn-Byng</h1>
                <h4>1st November 2023</h4>
            </div>
            {/*<button onClick={redirectToGoogle}>Sign In with Google</button>*/}
            <UploadModal fetchPhotos={updatePhotos}></UploadModal>
            <Photos photos={photos}/>
        </div>
    );
}

export default App;
