import {Box, Button, IconButton, Modal as MuiModal} from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import {useState} from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material/styles';


function Modal({children, props}) {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const modalWidth = () => {
        if (isLgBreakpoint) {
            return "50%";
        } else if (isSmBreakpoint) {
            return "70%";
        } else {
            return "80%";
        }
    };

    return (
        <div>
            <IconButton
                sx={{position: 'fixed', zIndex:100, bottom: 20, right: 20, borderRadius: 50, backgroundColor: "brown", color: "white", ":hover": {bgcolor: "#621616", color: "white"}}}
                variant="contained"
                size="large"
                onClick={handleOpen}
                aria-label="upload"
            >
                <UploadIcon fontSize="inherit" size="large" />
            </IconButton>
            <MuiModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: modalWidth(),
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="inherit" size="large"/>
                    </IconButton>
                    { children }
                </Box>
            </MuiModal>
        </div>
    );
}

export default Modal;