import axios from 'axios';
import {useEffect, useState} from 'react';
import ImageTimeline from "./Components/ImageTimeline";

function Photos({photos}) {

    return (<div>
        <ImageTimeline images={photos}/>
    </div>);
}

export default Photos;
