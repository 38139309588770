import * as React from 'react';
import {Cloudinary, CloudinaryImage} from "@cloudinary/url-gen";
import {AdvancedImage} from "@cloudinary/react";
import { format, quality } from "@cloudinary/url-gen/actions/delivery";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import {autoGood} from "@cloudinary/url-gen/qualifiers/quality";
import { limitFit } from "@cloudinary/url-gen/actions/resize";
import { blur } from "@cloudinary/url-gen/actions/effect";


export default function CloudinaryImageView({image}) {

    const public_id = image.public_id
    const cld = new Cloudinary({cloud: {cloudName: 'weddingapptest'}});

    const myImage = new CloudinaryImage(public_id, {cloudName: 'weddingapptest'})
        .resize(limitFit().width(600))
        .delivery(format(auto()))
        .delivery(quality(autoGood()));

    const placeholderImage = new CloudinaryImage(public_id, {cloudName: 'weddingapptest'})
        .effect(blur(500))
        .resize(limitFit().width(100))
        .delivery(quality(autoGood()));

    return (
        <div>
              <AdvancedImage
                  className={'cl-image'}
                  cldImg={myImage}
                  placeholderImg={placeholderImage}
              />
          </div>
      )
}